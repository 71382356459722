if (!Object.fromEntries) {
    Object.defineProperty(Object, "fromEntries", {
        value(entries) {
            const obj = {};

            for (const pair of entries) {
                if (Object(pair) !== pair) {
                    throw new TypeError('iterable for fromEntries should yield objects');
                }
                obj[pair[0]] = pair[1];
            }

            return obj;
        },
    });
}
